
import { defineComponent, onMounted } from 'vue';
import NavigationUiFooter from '@/components/NavigationUiFooter.vue';
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import { useStore } from 'vuex';


export default defineComponent({
  name: 'Layout',
  components: {
    NavigationUiFooter,
    LoadingOverlay,
  },
  setup() {
    const store = useStore();
    onMounted(() => {
      store.dispatch('getUserData');
    });
  }
});
