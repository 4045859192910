<template>
    <div class="navigation-ui-footer">
        <div class="navigation-ui-footer">
          <div class="navigation-ui-content-wrapper">
            <span class="navigation-ui-footer-content"> © immowelt GmbH 2021 <span>
                <a @click.prevent href="https://www.immowelt.de/immoweltag/agb">AGB</a>
              </span>
              <span>
                <a @click.prevent href="https://www.immowelt.de/immoweltag/datenschutz">Datenschutz</a>
              </span>
              <span>
                <a @click.prevent href="https://www.immowelt.de/immoweltag/impressum">Impressum</a>
              </span>
              <a @click.prevent href="#">Cookie Einstellungen</a>
            </span>
          </div>
        </div>
    </div>
</template>