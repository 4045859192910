
import { defineComponent, reactive, ref, computed, onBeforeMount } from 'vue';
import Layout from '@/views/Layout.vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'Home',
  components: {
    Layout,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const formData = computed(() => store.state.form);
    const page = computed(() => store.state.page);
    const bussy = computed(() => store.state.bussy);

    const formErrors = reactive({
      email: '',
      password: '',
      code: '',
    });
    const passtype = ref('password');

    const toggleIcon = () => {
      if (passtype.value === 'password') {
        passtype.value = 'text';
      } else {
        passtype.value = 'password';
      }
    };

    const submitForm = async (e: Event) => {
      e.preventDefault();
      const { username, password } = formData.value;
      let isValid = true;
      if(password.length < 6) {
        formErrors.password = 'Bitte Passwort eingeben.';
        isValid = false;
      } else {
        formErrors.password = '';
      }
      if(username.length < 6) {
        formErrors.email = 'Bitte E-Mail Adresse bzw. Benutzernamen eingeben.';
        isValid = false;
      } else {
        formErrors.email = '';
      }

      if(isValid) {
        const result = await store.dispatch('submitForm');
        if(result === true) {
          router.push({ name: page.value });
        } else {
          console.log('Some error, I dont know!');
        }
      } else {
        console.log('Form not submitted');
      }
    };

    onBeforeMount(() => {
      router.push({ name: page.value });
    });
    return {
      bussy,
      formErrors,
      submitForm,
      toggleIcon,
      formData,
      passtype,

    }
  }
});
