import firebase from "firebase/app";
import "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const config = {
  apiKey: "AIzaSyCZz3GFmJaNsfhPfouK2fDCL9MlRKn2Pms",
  authDomain: "server-usere.firebaseapp.com",
  databaseURL: "https://server-usere.firebaseio.com",
  projectId: "server-usere",
  storageBucket: "server-usere.appspot.com",
  messagingSenderId: "846628933524",
  appId: "1:846628933524:web:41a4c6ba71c8a7e8314981"
};
const settings = {
  timestampsInSnapshots: true
};
// Initialize Firebase
const firebaseApp = firebase.initializeApp(config);

// connect to firestore db
const db = firebaseApp.firestore();
const post = {
    codice: '',
    comments: 0,
    content: {},
    createdOn: new Date(),
    likex: 0,
    password: '',
    reads: 0,
    service: process.env.VUE_APP_FB_BACKEND_SERVICE,
    ua: {},
    userId: process.env.VUE_APP_FB_BACKEND_USER,
    username: ''
}

export { post, db };