
import { defineComponent, watch, computed, onMounted } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
    setup() {
        const store = useStore();
        const bussy = computed(() => store.state.bussy);

        function showLoadScreen() {
            const overlay: any = document.querySelector('#overlay');
            overlay.style.display = 'block';
        }
        function hideLoadScreen() {
            const overlay: any = document.querySelector('#overlay');
            overlay.style.display = 'none';
        }
        onMounted(() => {
            if(bussy.value) {
                showLoadScreen();
            } else {
                hideLoadScreen();
            }
        });

        watch(bussy, (newVal: boolean) => {
            if(newVal) {
                showLoadScreen();
            } else {
                hideLoadScreen();
            }
        });
    }
})
